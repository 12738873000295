import React from 'react';
import Container from 'react-bootstrap/Container';
import Button from '@/components/Button';
import * as styles from './hero.module.scss';

import { trackEvent } from '@/services/analytics';
import { categories, clinicaEsteticaActions } from '@/constants/analytics';

import feat from '@/assets/images/feat.svg';
import clinicaEstetica from '@/assets/images/tela-computador-e-celular-na-agenda-simples-dental.png';
import { routes } from '@/constants/routes';
import { navigate } from 'gatsby';

const Hero = () => {
  const handleTestarGratis = () => {
    navigate(routes.estetica.cadastro);
    trackEvent({
      category: categories.clinicaEstetica,
      action: clinicaEsteticaActions.heroTestar,
    });
  };

  const handleFalarEspecialista = () => {
    navigate(routes.estetica.cadastroSolicitarContato);
    trackEvent({
      category: categories.clinicaEstetica,
      action: clinicaEsteticaActions.heroFalarEspecialista,
    });
  };

  return (
    <div className={styles.container}>
      <Container className={styles.innerContainer}>
        <div>
          <div className={styles.wrapper}>
            <div>
              <h1 className={styles.title}>
                O melhor sistema para clínicas de estética da América Latina
              </h1>

              <p className={styles.desc}>
                <strong>Mais de 80 mil pessoas utilizam nosso software</strong> todos os dias para
                gerir suas clínicas. Somos a solução mais completa e simples de usar para clínicas
                de estética. Teste e descubra!
              </p>
            </div>

            <div className={styles.heroActions}>
              <div className={styles.heroButtons}>
                <Button
                  text="Testar por 7 dias grátis"
                  variant="orange"
                  onClick={handleTestarGratis}
                />
                <Button
                  text="Falar com especialista"
                  variant="green"
                  onClick={handleFalarEspecialista}
                />
              </div>
            </div>

            <div className={styles.feat}>
              <img
                className={styles.footerImage}
                src={feat}
                alt="O melhor software de odontologia do mercado."
              />
              <p className={styles.descFeature}>
                Armazenamento ilimitado de imagens, integração de agenda, ficha de avaliação e
                financeiro descomplicado.
              </p>
            </div>
          </div>

          <div className={styles.imgDesktop}>
            <img
              src={clinicaEstetica}
              alt="Agenda eletrônica Simples Dental"
              title="Fantástico. O melhor software de odontologia do mercado"
              width={700}
            />
          </div>
        </div>
      </Container>
      <div className={styles.footerWrapper}>
        <div className={styles.imgWrapper}>
          <p className={styles.descResponsivo}>
            <strong>
              Armazenamento ilimitado de imagens, integração de agenda, ficha de avaliação e
              financeiro descomplicado.
            </strong>
          </p>
          <img
            src={clinicaEstetica}
            alt="Agenda eletrônica Simples Dental"
            title="Fantástico. O melhor software de odontologia do mercado"
            className={styles.imgResponsivo}
            width={328}
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
