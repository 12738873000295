import { Seo } from '@/components';
import { ClinicaEsteticaDetails } from '@/constants/pageDetails';
import { Root } from '@/layouts';
import Features from '@/layouts/ClinicaEstetica/Features';
import Feedback from '@/layouts/ClinicaEstetica/Feedback';
import Footer from '@/layouts/ClinicaEstetica/Footer';
import EarnMore from '@/layouts/ClinicaEstetica/EarnMore';
import Hero from '@/layouts/ClinicaEstetica/Hero';
import Benefits from '@/layouts/ClinicaEstetica/Benefits';
import Cards from '@/layouts/MigracaoDados/Cards';
import { EsteticaCards } from '@/layouts/MigracaoDados/Cards/constants';
import Details from '@/layouts/MigracaoDados/Details';
import Feedbacks from '@/layouts/MigracaoDados/Feedbacks/Feedbacks';
import Numbers from '@/layouts/MigracaoDados/Numbers';
import React from 'react';
import { Container } from 'react-bootstrap';
import { categories, clinicaEsteticaActions } from '@/constants/analytics';
import { routes } from '@/constants/routes';

const ClinicaEstetica = () => {
  return (
    <>
      <Seo
        title="O Melhor Software para Clínicas de Estética"
        description="Descubra o Simples Dental, o software líder para clínicas de estética. Armazenamento ilimitado de imagens, integração de agenda, ficha de avaliação e financeiro descomplicado. Teste gratuitamente por 7 dias e otimize sua clínica de estética para faturar mais e fidelizar seus clientes. Junte-se à comunidade de mais de 80 mil usuários em todo o Brasil"
      />
      <Root variant="light" hero={<Hero />} customLogo="clinica-estetica">
        <Numbers />
        <Features />
        <Benefits />
        <Feedbacks title="Porque clientes amam nosso software para clínica de estética">
          <Feedback />
        </Feedbacks>
        <Container>
          <hr />
        </Container>
        <Cards
          title="Profissional da Estética, você se identifica com alguns desses desafios?"
          subtitle="Simplificamos a sua rotina"
          cards={EsteticaCards}
          link={routes.estetica.cadastro}
          gaMetadata={{
            category: categories.clinicaEstetica,
            action: clinicaEsteticaActions.cardsSimplificamosTestar,
          }}
        />
        <EarnMore />
        <Container>
          <hr />
        </Container>
        <Details
          title="Você com mais tempo para cuidar da beleza de seus clientes!"
          subtitle="Simplifique sua rotina de trabalho"
          items={ClinicaEsteticaDetails}
        />
        <Footer />
      </Root>
    </>
  );
};

export default ClinicaEstetica;
